import $ from 'jquery';
import IScroll from 'iscroll'

var burgerMenu = {

    vars: {
        burgerMenu: '.burger-menu',
        btnMobileMenu: '.btn-mobile-menu'
    },

    functs: {
        scrollInit: function () {
            burgerMenu.scroll = new IScroll(burgerMenu.vars.burgerMenu + ' .scroll-wrap', {
                mouseWheel: true,
                scrollbars: true,
                fadeScrollbars: true,
                preventDefault: false,
                resizePolling: 250,
                scrollX: false,
                interactiveScrollbars: true,
                scrollY: true
            });
        }
    },
    uninit: function () {
        $(document).off('click.burgerMenu');
    },
    init: function () {
        $(document)
            .ready(function () {
                burgerMenu.functs.scrollInit();
            })
            .on('click.burgerMenu', burgerMenu.vars.btnMobileMenu, function (e) {
                e.stopPropagation();
                $(burgerMenu.vars.burgerMenu).toggleClass('open');
                setTimeout(function () {
                    burgerMenu.scroll.refresh();
                }, 200);
            })
            .on('click.burgerMenu', burgerMenu.vars.burgerMenu + ' .menu-wrap', function (e) {
                e.stopPropagation();
                // $('.dropdown').removeClass('open');
            })
            .on('click.burgerMenu', burgerMenu.vars.burgerMenu + ' .item-login, ' + burgerMenu.vars.burgerMenu + ' .item-sign-up', function (e) {
                $(burgerMenu.vars.burgerMenu).removeClass('open');
                $('body, html').animate({scrollTop: 0}, 450);
            })
            .on('click.burgerMenu', function () {
                $(burgerMenu.vars.burgerMenu).removeClass('open');
            });
    }
};

burgerMenu.init();