import $ from 'jquery';

function showEffect() {
    $('[ data-scrollEffect="true"]').each(function () {
        var $this = $(this),
            hT = $this.offset().top,
            wH = $(window).height(),
            wS = $(document).scrollTop();
        if (wS + .5 * wH > hT){
            $(this).removeClass('translated');
        }
    })
}

$(window)
    .on('load', function () {
        showEffect();
    })
    .on('scroll', function () {
        showEffect();
    });