import $ from 'jquery';
import 'iscroll';

var menuGetGate = {

    vars : {
        menuGetRate: '.menu-get-rate',
        mgrField: '.mgr-field',
        mgrBtn: '.mgr-btn',
        menuDropdown: '.menu-dropdown'
    },

    uninit: function () {
        $(document).off('click.menuGetGate');
        $(window).off('scroll.menuGetGate');
    },

    functs: {
        /*setOrientation: function () {
            $(menuGetGate.vars.menuGetRate).filter('.menu-open').each(function () {
                var $this = $(this),
                    $menuDropdown = $this.find(menuGetGate.vars.menuDropdown),
                    $mgrField = $this.find(menuGetGate.vars.mgrField),
                    isOpen = $this.data().menuOpen,
                    mT, mH, sT, wH;

                if($menuDropdown.length){
                    if(!isOpen) $menuDropdown.show();
                    mT = $mgrField.offset().top + 1.25 * $mgrField.outerHeight();
                    mH = $menuDropdown.outerHeight();
                    sT = $(document).scrollTop();
                    wH = $(window).height();
                    if(!isOpen) $menuDropdown.hide();
                    $this.toggleClass('dropdown-top', mT + mH > sT + wH && mH <= wH);
                }
            });
        },*/
        open: function (menu) {
            var $menuDropdown = menu.find(menuGetGate.vars.menuDropdown);
            $menuDropdown.slideDown(225);
        },
        close: function (menu) {
            var $menuDropdown = menu.find(menuGetGate.vars.menuDropdown);
            $menuDropdown.slideUp(225);
            menu.removeClass('menu-open').find('.i-search input').attr('placeholder', menu.find('.i-search input').data().plholder);
        },
        listSort: function (input) {
            var $menuGetRate = input.closest(menuGetGate.vars.menuGetRate),
                $list = $menuGetRate.find('.list'),
                search = input.val().toLowerCase();

            if(search.trim() !== '') {
                $list.find('.item').sort(function (a, b) {
                    var s1 = $(a).find('.txt').text().toLowerCase().indexOf(search), s2 = $(b).find('.txt').text().toLowerCase().indexOf(search);
                    s1 = s1 === -1 ? 99999999999 : s1;
                    s2 = s2 === -1 ? 99999999999 : s2;
                    return s1 - s2;
                }).appendTo($list);
            }

            $menuGetRate.find('.i-search input').trigger('blur').trigger('focus');
        }
    },

    init: function () {

        $(document).on('click.menuGetGate', menuGetGate.vars.menuGetRate + ' ' + menuGetGate.vars.menuDropdown + ' .link', function (e) {
            e.preventDefault();

            var $this = $(this),
                $menuGetRate = $this.closest(menuGetGate.vars.menuGetRate),
                $menuDropdown = $this.closest(menuGetGate.vars.menuDropdown),
                $actItem, actTxt, actLink;

            $this.closest('.item').toggleClass('active').siblings().removeClass('active');
            $actItem = $menuDropdown.find('.item.active');
            actTxt = $actItem.find('.txt').text().trim();
            actLink = $actItem.find('.link').attr('href');
            if($actItem.length && actTxt.length && actLink !== undefined){
                $menuGetRate.data().link = {
                    txt: actTxt,
                    href: actLink
                };
                $menuGetRate.data().menuOpen = false;
                $menuGetRate.removeClass('menu-open');
                menuGetGate.functs.close($menuGetRate);
            } else {
                $menuGetRate.data().link = undefined;
            }
            $menuGetRate.find('.i-search input').val(actTxt.length? actTxt: '');
        });
        $(document).on('click.menuGetGate', menuGetGate.vars.menuGetRate + ' ' + menuGetGate.vars.mgrBtn, function (e) {

            e.preventDefault();
            var $this = $(this),
                $iSearch = $this.closest(menuGetGate.vars.menuGetRate).find('.i-search input'),
                $menuGetRate = $this.closest(menuGetGate.vars.menuGetRate);
            if($menuGetRate.data().link !== undefined && $menuGetRate.data().link.txt.toLowerCase() === $iSearch.val().toLowerCase()){
                window.location.href = $menuGetRate.data().link.href;
            } else {
                $iSearch.val('');
                $menuGetRate.find('.item').removeClass('active');
            }
            menuGetGate.functs.close($menuGetRate);
        });

        /*$(document).on('blur.menuGetGate', menuGetGate.vars.menuGetRate + ' .i-search input', function (e) {
            console.log('-blur');
        });*/

        $(document).on('focus.menuGetGate', menuGetGate.vars.menuGetRate + ':not(.menu-open) .i-search input', function (e) {
 
            var $this = $(this),
                $menuGetRate = $this.closest(menuGetGate.vars.menuGetRate);
            $this.data().plholder = $this.attr('placeholder');
            $this.attr('placeholder', '');
            if($menuGetRate.data().menuOpen){
                $menuGetRate.data().menuOpen = false;
                menuGetGate.functs.close($menuGetRate);
            } else{
                $menuGetRate.data().menuOpen = true;
                menuGetGate.functs.open($menuGetRate);
            }
            $menuGetRate.toggleClass('menu-open', $menuGetRate.data().menuOpen);
            menuGetGate.functs.listSort($(this));
        });
        $(document).on('change.menuGetGate keyup.menuGetGate', menuGetGate.vars.menuGetRate + ' .i-search input', function (e) {
            var $this = $(this),
                $menuGetRate = $this.closest(menuGetGate.vars.menuGetRate),
                search = $this.val().toLowerCase();
            menuGetGate.functs.listSort($(this));

            $this.closest(menuGetGate.vars.menuGetRate).find('.list .item').each(function () { var $item = $(this), itTxt = $item.find('.txt').text().toLowerCase(), isLink = itTxt === search; $(this).toggleClass('active', isLink); if (isLink) { $menuGetRate.data().link = { txt: itTxt, href: $item.find('.link').attr('href')}; } });
        });
        $(document).on('click.menuGetGate', menuGetGate.vars.menuGetRate, function (e) {
 
            e.stopPropagation();
        });
        $(document).on('click.menuGetGate', function (e) {
 
            $(menuGetGate.vars.menuGetRate).filter('.menu-open').each(function () {
                var $menuGetRate = $(this);
                $menuGetRate.data().menuOpen = false;
                $menuGetRate.removeClass('menu-open');
                menuGetGate.functs.close($menuGetRate);
            });
        });
    }
};

menuGetGate.init();